import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import loadable from '@loadable/component';

// Imports => Constants
import { ICONS, KEYS, PERMISSIONS, ROUTES, TITLES, TYPES } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
const AcSelectPortalCompany = loadable(() =>
  import('@molecules/ac-select-company/ac-select-portal-company')
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcWizardModal = loadable(() =>
  import('@components/ac-wizard-modal/ac-wizard-modal')
);
const AcMultiSelect = loadable(() =>
  import('@molecules/ac-multi-select/ac-multi-select')
);
const AcTextInput = loadable(() =>
  import('@atoms/ac-text-input/ac-text-input.web')
);
const AcIconWeb = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const EQUIPMENT_GROUP = {
  CONTROL_UNITS: 3,
  HAMMERS: 1,
  POWER_PACKS: 2,
};
let WIZARD_STEPS = {
  COMPANY: 0,
  CONTROL_UNITS: 1,
  POWER_PACKS: 2,
  HAMMERS: 3,
  NAME: 4,
};

const AcConfigurationModal = ({
  store: { configurations, ui, companies, equipment, profile },
  data,
  modify = false,
}) => {
  const navigate = useNavigate();

  const { wizard } = configurations;
  const [wizardStep, setWizardStep] = useState(null);
  const [validated, setValidated] = useState(false);
  const [isC66Series, setIsC66Series] = useState(false);
  const [errors, setErrors] = useState({});
  const { can, cannot } = usePermissions();

  const { handleInputChange, handleInputValidation } = useFormActions({
    fields: configurations.wizard,
    setFields: (data) => configurations.set(KEYS.WIZARD, data),
    errors,
    setErrors,
  });

  useEffect(() => {
    if (!profile?.current_profile?.is_iqip_user) {
      delete WIZARD_STEPS.COMPANY;

      WIZARD_STEPS = {
        CONTROL_UNITS: 0,
        POWER_PACKS: 1,
        HAMMERS: 2,
        NAME: 3,
      };
    }
  }, [profile?.current_profile?.is_iqip_user]);

  const submitSelectedConfiguration = async () => {
    if (!wizard.name) return;

    equipment.setLoading(true);

    let action = 'store';

    if (modify) action = 'update';

    await configurations[action](data?.id, {
      name: wizard.name,
      company_id: companies.current_company?.id,
      equipment: configurations.current_wizard_equipment,
    })
      .then(async (response) => {
        if (configurations.current_configuration?.id) {
          const route = generatePath(ROUTES.CONFIGURATION_DETAIL.path, {
            id: configurations.current_configuration?.id,
          });
          navigate(route, { replace: true });

          equipment.setLoading(false);
          configurations.reset('wizard');
          await ui.reset(KEYS.MODAL);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          setErrors(error.response.data.errors);
          equipment.setLoading(false);
        }
      });

    /*
    try {
      if (modify) {
        await configurations.update(data?.id, {
          name: wizard.name,
          company_id: companies.current_company?.id,
          equipment: configurations.current_wizard_equipment,
        });
      } else {
        await configurations.store({
          name: wizard.name,
          company_id: companies.current_company?.id,
          equipment: configurations.current_wizard_equipment,
        });
      }

      if (configurations.current_configuration?.id) {
        const route = generatePath(ROUTES.CONFIGURATION_DETAIL.path, {
          id: configurations.current_configuration?.id,
        });
        navigate(route, { replace: true });
      }
    } catch (error) {
      if (error?.response?.data?.errors) {
        setErrors(error.response.data.errors);
      }
    } finally {
      equipment.setLoading(false);
      configurations.reset('wizard');
      await ui.reset(KEYS.MODAL);
    }
*/
  };

  const validateSelectedConfiguration = async (goToStep) => {
    if (validated) {
      return await submitSelectedConfiguration();
    } else {
      const configuration = {
        name: `new-configuration-${AcUUID()}`,
        company_id: companies.current_company?.id,
        equipment: configurations.current_wizard_equipment,
      };

      ui.setValue(KEYS.MODAL, 'height', 600);
      equipment.setLoading(true);
      setErrors({});

      if (AcIsSet(data)) {
        await configurations
          .updateDryRun(data.id, configuration)
          .then(() => {
            equipment.setLoading(false);
            setValidated(true);
            goToStep(1);
          })
          .catch((error) => {
            if (error?.response?.data?.errors) {
              setErrors(error.response.data.errors);
              ui.setValue(KEYS.MODAL, 'height', 700);
            }
            setValidated(false);
            equipment.setLoading(false);
          });
      } else {
        await configurations
          .storeDryRun(configuration)
          .then(() => {
            equipment.setLoading(false);
            setValidated(true);
            goToStep(1);
          })
          .catch((error) => {
            if (error?.response?.data?.errors) {
              setErrors(error.response.data.errors);
              ui.setValue(KEYS.MODAL, 'height', 700);
            }
            setValidated(false);
            equipment.setLoading(false);
          });
      }
    }
  };

  const handleNameValidation = useCallback(() => {
    setErrors({});
    return errors?.name?.length ? errors.name?.[0] : false;
  }, [errors]);

  const getNameInputOptions = useMemo(
    () => ({
      type: TYPES.TEXT,
      label: 'Configuration name (e.g. S-280 spread)',
      name: 'name',
      value: wizard.name,
      callback: handleInputChange,
      validation: handleNameValidation,
      error: errors?.name?.[0],
      focus: true,
    }),
    [errors, wizard.name]
  );

  const controlUnitCollection = useMemo(
    () =>
      equipment.current_equipment_list.map(
        ({ id, object_no, equipment_type, series }) => ({
          id,
          object_no,
          type: equipment_type,
          series,
        })
      ),
    [equipment.current_equipment_list]
  );

  const hammerPowerpackCollection = useMemo(() => {
    const CURRENT_STEP =
      wizardStep === WIZARD_STEPS.HAMMERS ? 'HAMMERS' : 'POWER PACKS';

    return equipment.current_equipment_list
      .filter(
        ({ equipment_group }) =>
          equipment_group?.toUpperCase?.() === CURRENT_STEP
      )
      .map(({ id, object_no, equipment_meta: meta }) => ({
        id,
        object_no,
        type: meta.type,
        serie: meta.series,
      }));
  }, [equipment.current_equipment_list, wizardStep]);

  const steps = useMemo(() => {
    const group = [
      {
        title: TITLES.SELECT_CONTROL_UNIT,
        step: WIZARD_STEPS.CONTROL_UNIT,

        children: (
          <AcMultiSelect
            {...{
              alwaysShowSearch: true,
              key: KEYS.CONTROL_UNITS,
              multiple: false,
              instance: { is_busy: equipment.is_loading },
              collection: controlUnitCollection,
              initialSelection: wizard.control_units,
              onChange: (selection) => {
                const selectedControlUnit = controlUnitCollection.find(
                  (n) => n.id === selection?.[0]
                );
                const isC66 = selectedControlUnit?.series === '66';
                setIsC66Series(isC66);

                configurations.setState(
                  KEYS.WIZARD,
                  KEYS.CONTROL_UNITS,
                  selection
                );
              },
            }}
          />
        ),
        submitButton: {
          disabled: !wizard?.control_units?.length,
        },
      },
      {
        title: TITLES.SELECT_POWER_PACKS,
        step: WIZARD_STEPS.POWER_PACKS,

        children: (
          <AcMultiSelect
            {...{
              alwaysShowSearch: true,
              key: KEYS.POWER_PACKS,
              instance: { is_busy: equipment.is_loading },
              collection: hammerPowerpackCollection,
              initialSelection: wizard.power_packs,
              onChange: (selection) => {
                configurations.setState(
                  KEYS.WIZARD,
                  KEYS.POWER_PACKS,
                  selection
                );
              },
            }}
          />
        ),
        submitButton: {
          // disabled: !wizard.power_packs.length,
          children: (
            <span>
              Next <sub>{wizard.power_packs.length}</sub>
            </span>
          ),
        },
      },
      {
        title: TITLES.SELECT_HAMMERS,
        step: WIZARD_STEPS.HAMMERS,

        children: (
          <>
            <AcMultiSelect
              {...{
                alwaysShowSearch: true,
                key: KEYS.HAMMERS,
                multiple: isC66Series ? false : true,
                instance: { is_busy: equipment.is_loading },
                collection: hammerPowerpackCollection,
                initialSelection: wizard.hammers,
                onChange: (selection) => {
                  configurations.setState(KEYS.WIZARD, KEYS.HAMMERS, selection);
                },
              }}
            />
            {errors?.equipment?.length && (
              <AcContainer className={'h-margin-y-25'}>
                <AcRow
                  className={
                    'ac-configuration-modal__error h-flex-v-align-center'
                  }
                >
                  <AcIconWeb
                    icon={ICONS.CRITICAL_ALERT}
                    className="ac-configuration-modal__error-icon"
                  />
                  <AcColumn className="ac-configuration-modal__error-content">
                    {errors.equipment?.[0]}
                  </AcColumn>
                </AcRow>
              </AcContainer>
            )}
          </>
        ),
        submitButton: {
          // disabled: !wizard.hammers.length,
          children: (
            <span>
              Next <sub>{wizard.hammers.length}</sub>
            </span>
          ),
          callback: (goToStep) => validateSelectedConfiguration(goToStep),
        },
      },
      {
        title: TITLES.SAVE_CONFIGURATION,
        step: WIZARD_STEPS.NAME,
        loading: configurations.is_busy,
        children: (
          <>
            <p>Please give the configuration a name.</p>
            <AcTextInput {...getNameInputOptions} />
          </>
        ),
        submitButton: {
          disabled: !wizard.name,
          children: modify ? (
            <span>Save configuration</span>
          ) : (
            <span>Create configuration</span>
          ),
          callback: (goToStep) => submitSelectedConfiguration(goToStep),
        },
      },
    ];

    if (profile.current_profile?.is_iqip_user) {
      group.unshift({
        title: TITLES.SELECT_COMPANY,
        step: WIZARD_STEPS.COMPANY,
        children: <AcSelectPortalCompany prefill={AcIsSet(data)} />,
        submitButton: { disabled: !companies.current_company?.name },
      });
    }

    return group;
  }, [
    modify,
    isC66Series,
    equipment.is_loading,
    configurations.is_busy,
    companies.current_company,
    wizard.control_units,
    wizard.hammers,
    wizard.power_packs,
    wizard.name,
    controlUnitCollection,
    hammerPowerpackCollection,
    profile.current_profile,
  ]);

  const setInitialEquipment = useCallback(() => {
    const wizardEquipment = data.equipment.reduce(
      (wizardConfig, { equipment_group, id }) => {
        const GROUP = equipment_group.replace(/ /gm, '_').toLowerCase();

        // Filter equipment that is not in response list
        const equipmentMatches = equipment.current_equipment_list.some(
          ({ id: _id }) => id === _id
        );

        if (equipmentMatches && !wizardConfig[GROUP].includes(id)) {
          wizardConfig[GROUP].push(id);
        }

        return wizardConfig;
      },
      configurations.wizard
    );

    configurations.set(KEYS.WIZARD, wizardEquipment);
  }, [data, equipment.current_equipment_list]);

  useEffect(() => {
    if (data) {
      companies.set(KEYS.COMPANY, data.company);
      configurations.setState(KEYS.WIZARD, 'name', data.name);
    }
  }, []);

  useEffect(async () => {
    const currentCompanyId = companies.current_company?.id;
    const currentControlUnitId = wizard.control_units?.[0];

    setErrors({});

    console.log('wizardStep', wizardStep);

    switch (wizardStep) {
      case WIZARD_STEPS.COMPANY: {
        setValidated(false);
        equipment.reset(KEYS.WIZARD);
        ui.setValue(KEYS.MODAL, 'width', 600);
        ui.setValue(KEYS.MODAL, 'height', null);
        break;
      }
      case WIZARD_STEPS.CONTROL_UNITS: {
        setValidated(false);
        equipment.reset(KEYS.WIZARD);
        ui.setValue(KEYS.MODAL, 'width', 732);
        ui.setValue(KEYS.MODAL, 'height', null);

        if (profile?.current_profile?.is_iqip_user) {
          await equipment.list({
            group: EQUIPMENT_GROUP.CONTROL_UNITS,
            company_id: currentCompanyId,
          });
        } else {
          await equipment.list({
            group: EQUIPMENT_GROUP.CONTROL_UNITS,
          });
        }

        if (data) setInitialEquipment();

        break;
      }
      case WIZARD_STEPS.POWER_PACKS: {
        setValidated(false);
        if (profile?.current_profile?.is_iqip_user) {
          await equipment.list({
            group: [EQUIPMENT_GROUP.POWER_PACKS, EQUIPMENT_GROUP.HAMMERS],
            compatible_with: currentControlUnitId,
            company_id: currentCompanyId,
          });
        } else {
          await equipment.list({
            group: [EQUIPMENT_GROUP.POWER_PACKS, EQUIPMENT_GROUP.HAMMERS],
            compatible_with: currentControlUnitId,
          });
        }

        if (data) setInitialEquipment();

        break;
      }
      case WIZARD_STEPS.HAMMERS: {
        setValidated(false);
        ui.setValue(KEYS.MODAL, 'width', 732);
        ui.setValue(KEYS.MODAL, 'height', null);
        break;
      }
      case WIZARD_STEPS.NAME: {
        ui.setValue(KEYS.MODAL, 'width', 600);
        ui.setValue(KEYS.MODAL, 'height', null);
        break;
      }
    }
  }, [wizardStep, profile?.current_profile?.is_iqip_user]);

  const renderLoader = useMemo(() => {
    if (!configurations.is_busy) return null;

    return <AcLoader cover={true} loading={true} />;
  }, [configurations.is_busy]);

  return (
    <>
      <AcWizardModal
        className={'ac-configuration-modal'}
        onStep={(step) => setWizardStep(step)}
        onCancel={() => configurations.reset(KEYS.WIZARD)}
        steps={steps}
        progress
        forceRerender
      />

      {renderLoader}
    </>
  );
};

export default withStore(observer(AcConfigurationModal));
