import { useEffect, useState } from 'react';
import { AcFormatDate, AcIsSet } from '@utils';
import { DATETIME_FORMATS } from '@constants';

export const useTermsAndConditions = (store) => {
  if (!AcIsSet(store)) {
    console.warn(
      `[hook] UseTermsAndConduction => store is not set. Please provide a valid MobX Store instance`
    );
    return;
  }

  const lastUpdated = 1727959839;

  const { is_authorized, current_accept_terms } = store.auth;

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(null);

  useEffect(() => {
    if (!is_authorized) return;

    if (!current_accept_terms) {
      setTermsAndConditionsAccepted(false);
      return;
    }

    const timestamp = AcFormatDate(
      current_accept_terms,
      DATETIME_FORMATS.RAW_DATETIME_WITH_YEAR_NO_COMMA,
      DATETIME_FORMATS.TIMESTAMP
    );

    const acceptedLatest = timestamp >= lastUpdated;

    setTermsAndConditionsAccepted(acceptedLatest);
  }, [is_authorized, current_accept_terms]);

  const content = (
    <embed
      src={process.env.API_URL + '/assets/pdf/terms-iqip-v1.pdf'}
      type={'application/pdf'}
      width={'700px'}
      height={'600px'}
    />
  );

  return {
    lastUpdated,
    content,
    termsAndConditionsAccepted,
  };
};
