export const PERMISSIONS = {
  ALERT: {
    READ: 'ALERT.READ',
    UPDATE: 'ALERT.UPDATE',
  },
  ADVANCED_PASSWORD: {
    READ: 'ADVANCED_PASSWORD.READ',
    UPDATE: 'ADVANCED_PASSWORD.UPDATE',
  },
  BASIC_PASSWORD: {
    READ: 'BASIC_PASSWORD.READ',
    UPDATE: 'BASIC_PASSWORD.UPDATE',
  },
  COMPANY: {
    READ: 'COMPANY.READ',
    READ_ALL: 'COMPANY.READ_ALL',
    READ_SELF: 'COMPANY.READ_SELF',

    UPDATE: 'COMPANY.UPDATE',
    UPDATE_ALL: 'COMPANY.UPDATE_ALL',

    CREATE: 'COMPANY.CREATE',
    IMPORT: 'COMPANY.IMPORT',
    DELETE: 'COMPANY.DELETE',
  },
  CONFIGURATION: {
    READ: 'CONFIGURATION.READ',
    READ_ALL: 'CONFIGURATION.READ_ALL',
    MANAGE: 'CONFIGURATION.MANAGE',
    IMPORT_IN_PROJECT: 'CONFIGURATION.IMPORT_IN_PROJECT',
    SEND_CONFIGURATION_READ: 'CONFIGURATION.SEND_CONFIGURATION_READ',
  },
  CONTRACT: {
    READ: 'CONTRACT.READ',
    READ_ALL: 'CONTRACT.READ_ALL',
    MANAGE: 'CONTRACT.MANAGE',
  },
  CREDENTIALS: {
    READ: 'CREDENTIALS.READ',
    MANAGE: 'CREDENTIALS.MANAGE',
  },
  EQUIPMENT: {
    CREATE: 'EQUIPMENT.CREATE',
    DELETE: 'EQUIPMENT.DELETE',
    MANAGE_DETAILS: 'EQUIPMENT.MANAGE_DETAILS',
    MANAGE_SETTINGS: 'EQUIPMENT.MANAGE_SETTINGS',
    MANAGE_PARAMETERS: 'EQUIPMENT.MANAGE_PARAMETERS',
    READ: 'EQUIPMENT.READ',
    READ_ALL: 'EQUIPMENT.READ_ALL',
    READ_PARAMETERS: 'EQUIPMENT.READ_PARAMETERS',
    READ_SETTINGS: 'EQUIPMENT.READ_SETTINGS',
  },
  EQUIPMENT_TYPE: {
    CREATE: 'EQUIPMENT_TYPE.CREATE',
    DELETE: 'EQUIPMENT_TYPE.DELETE',
    MANAGE_PARAMETERS: 'EQUIPMENT_TYPE.MANAGE_PARAMETERS',
    READ: 'EQUIPMENT_TYPE.READ',
    READ_PARAMETERS: 'EQUIPMENT_TYPE.READ_PARAMETERS',
    UPDATE: 'EQUIPMENT_TYPE.UPDATE',
    CREATE_SOFTWARE: 'EQUIPMENT_TYPE.CREATE_SOFTWARE',
    DELETE_SOFTWARE: 'EQUIPMENT_TYPE.DELETE_SOFTWARE',
    UPDATE_SOFTWARE: 'EQUIPMENT_TYPE.UPDATE_SOFTWARE',
    DOWNLOAD_SOFTWARE: 'EQUIPMENT_TYPE.DOWNLOAD_SOFTWARE',
  },
  JEAN_LUTZ_USERNAME: {
    READ: 'JEAN_LUTZ_USERNAME.READ',
    UPDATE: 'JEAN_LUTZ_USERNAME.UPDATE',
  },
  PILE: {
    CREATE: 'PILE.CREATE',
    DELETE: 'PILE.DELETE',
    EXPORT_CONTROL_UNIT: 'PILE.EXPORT_CONTROL_UNIT',
    EXPORT_XLSX: 'PILE.EXPORT_XLSX',
    IMPORT: 'PILE.IMPORT',
    READ: 'PILE.READ',
    READ_ALL: 'PILE.READ_ALL',
    READ_TRANSFERS: 'PILE.READ_TRANSFERS',
    READ_ALL_TRANSFERS: 'PILE.READ_ALL_TRANSFERS',
    SEND: 'PILE.SEND',
    UPDATE: 'PILE.UPDATE',
  },
  PROFILE: {
    READ_JEAN_LUTZ_CREDENTIALS: 'PROFILE.READ_JEAN_LUTZ_CREDENTIALS',
    MANAGE_JEAN_LUTZ_CREDENTIALS: 'PROFILE.MANAGE_JEAN_LUTZ_CREDENTIALS',
  },
  PROJECT: {
    CREATE: 'PROJECT.CREATE',
    CREATE_ALL: 'PROJECT.CREATE_ALL',
    DELETE: 'PROJECT.DELETE',
    IMPORT: 'PROJECT.IMPORT',
    READ: 'PROJECT.READ',
    READ_ALL: 'PROJECT.READ_ALL',
    READ_COMPANY_UID: 'PROJECT.READ_COMPANY_UID',
    READ_COMPANY_NAME: 'PROJECT.READ_COMPANY_NAME',
    UPDATE: 'PROJECT.UPDATE',
    UPDATE_COMPANY_UID: 'PROJECT.UPDATE_COMPANY_UID',
    UPDATE_COMPANY_NAME: 'PROJECT.UPDATE_COMPANY_NAME',
    UPDATE_CONFIGURATIONS: 'PROJECT.UPDATE_CONFIGURATIONS',
  },
  REPORT: {
    READ: 'REPORT.READ',
    DELETE: 'REPORT.DELETE',
    RESTORE: 'REPORT.RESTORE',
    DOWNLOAD: 'REPORT.DOWNLOAD',
    UPLOAD_FDL: 'REPORT.UPLOAD_FDL',
  },
  SOFTWARE: {
    READ: 'SOFTWARE.READ',
    PUSH: 'SOFTWARE.PUSH',
    DOWNLOAD: 'SOFTWARE.DOWNLOAD',
  },
  USER: {
    READ: 'USER.READ',
    READ_ALL: 'USER.READ_ALL',
    MANAGE: 'USER.MANAGE',
    MANAGE_ALL: 'USER.MANAGE_ALL',
    MANAGE_IQIP: 'USER.MANAGE_IQIP',
    IMPERSONATE: 'USER.IMPERSONATE',
    READ_PERMISSION: 'USER.READ_PERMISSIONS',
    READ_JL_NAME: 'JEAN_LUTZ_USERNAME.READ',
    UPDATE_JL_NAME: 'JEAN_LUTZ_USERNAME.UPDATE',
    READ_BASIC_PASS: 'BASIC_PASSWORD.READ',
    UPDATE_BASIC_PASS: 'BASIC_PASSWORD.UPDATE',
    READ_ADVANCED_PASS: 'ADVANCED_PASSWORD.READ',
    UPDATE_ADVANCED_PASS: 'ADVANCED_PASSWORD.UPDATE',
  },
  NAVIGATION: {},
};

export default PERMISSIONS;
