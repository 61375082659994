// Imports => React
import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import {
  ICONS,
  KEYS,
  PERMISSIONS,
  ROLES,
  ROUTES,
  THEMES,
  TITLES,
  TYPES,
} from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcIsUndefined,
  AcSortBy,
  AcFormatInternalURI,
  AcFormatDate,
  AcFormatRole,
} from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
const AcCheckPermissions = loadable(() =>
  import('@molecules/ac-check-permissions/ac-check-permissions.web')
);
const AcEditUserModal = loadable(() =>
  import('@molecules/ac-edit-user-modal/ac-edit-user-modal.web')
);
const AcEditUserCredentialsModal = loadable(() =>
  import(
    '@molecules/ac-edit-user-credentials-modal/ac-edit-user-credentials-modal.web'
  )
);
const AcCardContextualMenu = loadable(() =>
  import('@components/ac-card-contextual-menu/ac-card-contextual-menu.web')
);

// Imports => Components
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcToggleInput from '@atoms/ac-toggle-input/ac-toggle-input.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-user-detail-overview-tab',
};

const AcUserDetailOverviewTab = ({ data, store: { users, companies, ui } }) => {
  const { can, cannot, is, canAssignRoles } = usePermissions();
  const navigate = useNavigate();

  let raw_fields = {
    roles: (data && data.roles.slice()) || [],
  };

  const [fields, setFields] = useState(raw_fields);

  const { handleInputChange } = useFormActions({
    fields,
    setFields,
  });

  useEffect(() => {
    if (canAssignRoles()) {
      let params = {};
      if (data?.is_iqip_user) {
        params.iqipRoles = 1;
      } else {
        params.companyRoles = 1;
      }
      users.get_roles(params);
    }
  }, [data?.is_iqip_user]);

  const handleUpdateUser = () => {
    if (users.is_busy) return;

    if (AcIsSet(data) && AcIsSet(data.roles)) {
      if (AcIsSet(fields) && AcIsSet(fields.roles)) {
        users.update(data, fields).catch((error) => {
          users.get_by_id(data.id);
        });
      }
    }
  };

  const handleUpdateUserRoles = () => {
    if (users.is_busy) return;

    if (AcIsSet(data) && AcIsSet(data.roles)) {
      if (AcIsSet(fields) && AcIsSet(fields.roles)) {
        users
          .patch({ id: data.id, name: data.name, roles: fields.roles })
          .catch((error) => {
            users.get_by_id(data.id);
          });
      }
    }
  };

  const displayEditCredentialsModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EDIT_USER_CREDENTIALS,
      body: <AcEditUserCredentialsModal data={data} submit={users.update} />,
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const handleAssignCompaniesToNewUser = async (selection) => {
    return new Promise(async (resolve) => {
      let obj = data;

      obj.companies = selection;

      await users.update(obj).then((response) => {
        navigate(ROUTES.USER_DETAIL.path.replace(':id', response.id));
      });

      resolve();
    });
  };

  const displayEditCompanyModal = useCallback(async () => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    if (cannot(PERMISSIONS.USER.MANAGE_ALL)) return;

    await companies.list({ options: true }, false);
    const collection = companies.current_companies_list;
    const initialSelection = data?.companies.map((company) => company.id);

    ui.multi_select({
      instance: users,
      progress: {
        total_steps: 1,
        current_step: 0,
      },
      collection,
      initialSelection,
      multiple: true,
      alwaysShowSearch: true,
      title: TITLES.ASSIGN_COMPANIES_TO_USER,
      wide: false,
      introduction: '<p>Select 1 or more companies to assign this user to.</p>',
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
      verify_errors: false,
      confirm: {
        label: can(PERMISSIONS.USER.MANAGE_ALL) ? 'Continue' : 'Save user',
        callback: (selection) => {
          return handleAssignCompaniesToNewUser(selection);
        },
      },
    });
  }, [companies, data?.companies]);

  const displayEditModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: `${TITLES.EDIT_USER}: ${data.name}`,
      progress: {
        total_steps: 1,
        current_step: 0,
      },
      body: (
        <AcEditUserModal
          data={data}
          multistep={{
            roles: false,
            companies: false,
          }}
          submit={users.update}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const getContextMenu = useMemo(() => {
    const collection = [
      [
        {
          label: 'Update user details',
          icon: ICONS.EDIT_OUTLINE,
          callback: displayEditModal,
          disabled: cannot(PERMISSIONS.USER.MANAGE),
        },
        {
          label: `Update ${
            data.companies?.length === 1 ? 'company' : 'companies'
          }`,
          icon: ICONS.APARTMENT,
          callback: displayEditCompanyModal,
          disabled: cannot(PERMISSIONS.USER.MANAGE_ALL),
        },
      ],
    ];

    return <AcCardContextualMenu collection={collection} />;
  }, [data, displayEditModal, displayEditCompanyModal]);

  const renderDetails = useMemo(() => {
    if (!data) return null;

    const { name, email, phone, company, companies, is_iqip_user } = data;

    const result = [
      {
        label: 'Name',
        value: name || '-',
      },
      {
        label: 'Email address',
        value: email || '-',
      },
      {
        label: 'Phone number',
        value: phone || '-',
      },
    ];

    if (companies?.length) {
      result.push({
        label: companies?.length === 1 ? 'Company' : 'Companies',
        value: companies
          ?.map((obj) => {
            return obj.name;
          })
          .join(', '),
      });
    } else if (AcIsSet(company)) {
      const company_route =
        (can(PERMISSIONS.COMPANY.READ) &&
          company &&
          AcFormatInternalURI(
            { id: company.id, entity: KEYS.COMPANY },
            'View company'
          )) ||
        null;

      result.push({
        label: 'Company',
        value: (company && company.name) || '-',
        type: TYPES.LINK,
        to: company_route,
      });
    }

    const edit = can(PERMISSIONS.USER.MANAGE) && displayEditModal;
    const context =
      can(PERMISSIONS.USER.MANAGE_ALL) && !data.is_iqip_user && getContextMenu;

    const restProps = {};

    if (!data.is_iqip_user && can(PERMISSIONS.USER.MANAGE_ALL)) {
      restProps.context = context;
    } else {
      restProps.edit = edit;
    }

    return (
      <AcDetailsCard title={TITLES.DETAILS} items={result} {...restProps} />
    );
  }, [data, displayEditModal, getContextMenu]);

  const renderRoles = useMemo(() => {
    if (!AcIsSet(data)) return null;
    if (!users.current_roles) return null;

    const collection = Object.values(ROLES);

    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const role = collection[n];

      const available = AcIsSet(
        users.current_roles.find((item) => item.name === role)
      );
      if (available) {
        const callback = async (event, name, value, type, selected) => {
          await handleInputChange(event, name, value, type, selected);
          handleUpdateUserRoles();
        };

        const options = {
          type: TYPES.CHECKBOX,
          name: 'roles',
          id: `ac-roles-toggle-${role}`,
          value: role,
          checked: fields.roles && fields.roles.indexOf(role) > -1,
          disabled: users.is_busy || cannot(PERMISSIONS.USER.MANAGE),
          callback: can(PERMISSIONS.USER.MANAGE) && callback,
        };

        let label = AcFormatRole(role);

        const object = (
          <AcRow key={`ac-roles-toggle-row-${role}`}>
            <AcColumn xs={12}>
              <AcToggleInput {...options}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: label,
                  }}
                />
              </AcToggleInput>
            </AcColumn>
          </AcRow>
        );

        result.push(object);
      }
    }

    return result;
  }, [data, fields, fields.roles, users.is_busy, users.current_roles]);

  const canReadOrUpdateCUCredentials = useMemo(() => {
    return (
      can(PERMISSIONS.BASIC_PASSWORD.READ) &&
      (is(ROLES.OPERATOR_A, data.roles) ||
        is(ROLES.OPERATOR_B, data.roles) ||
        is(ROLES.OPERATOR_C, data.roles) ||
        is(ROLES.SERVICE_ENGINEER, data.roles))
    );
  }, [data?.roles]);

  const renderCredentials = useMemo(() => {
    if (!data) return null;
    if (!canReadOrUpdateCUCredentials) return null;

    const { jean_lutz_username, basic_password } = data;

    let result = [
      {
        label: 'Username',
        value: jean_lutz_username || '-',
      },
      { label: 'Basic password', value: basic_password || '-' },
    ];

    let edit = null;

    if (can(PERMISSIONS.BASIC_PASSWORD.UPDATE)) {
      edit = displayEditCredentialsModal;
    }

    return <AcDetailsCard title={TITLES.DETAILS} items={result} edit={edit} />;
  }, [data, canReadOrUpdateCUCredentials]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  User details
                </AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>{renderDetails}</AcColumn>
            </AcRow>
          </AcColumn>

          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  User roles
                </AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcCard>{renderRoles}</AcCard>
              </AcColumn>
            </AcRow>
          </AcColumn>
        </AcRow>

        {data && canReadOrUpdateCUCredentials && (
          <AcRow>
            <AcColumn xs={12} sm={6}>
              <AcRow className={'h-margin-bottom-25'}>
                <AcColumn>
                  <AcHeading tag={'h2'} rank={5}>
                    Control unit credentials
                  </AcHeading>
                  <AcRichContent
                    content={
                      '<p>These credentials give access to control units this user is assigned to.</p>'
                    }
                  />
                </AcColumn>
              </AcRow>

              <AcRow>
                <AcColumn>{renderCredentials}</AcColumn>
              </AcRow>
            </AcColumn>
          </AcRow>
        )}
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcUserDetailOverviewTab));
