import React, {
	useEffect,
	useState,
	useCallback,
	useMemo,
	useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { DEFAULT_ROUTE, ICONS, KEYS, SIZES, THEMES, TITLES } from '@constants';

// Imports => Hooks
import { useUIActions } from '@hooks';

// Imports => Utilities
import { AcIsSet, AcFormatInitials } from '@utils';

// Imports => Atoms
const AcRipple = loadable(() => import('@atoms/ac-ripple/ac-ripple.web'));
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcRichContent = loadable(() =>
	import('@atoms/ac-rich-content/ac-rich-content.web')
);

const _CLASSES = {
	MAIN: 'ac-account-switch',
	OPEN: 'ac-account-switch--open',
	INITIALS: 'ac-account-switch__initials',
	DETAILS: {
		MAIN: 'ac-account-switch__details',
		NAME: 'ac-account-switch__name',
		SUBLINE: 'ac-account-switch__subline',
		POINTER: 'ac-account-switch__pointer',
	},
	TOGGLE: {
		MAIN: 'ac-account-switch__toggle',
		ICON: 'ac-account-switch__toggle-icon',
	},
	DROPDOWN: {
		MAIN: 'ac-account-switch-dropdown',
		WRP: 'ac-account-switch-dropdown-wrp',
		LIST: 'ac-account-switch-dropdown__list',
		ITEM: 'ac-account-switch-dropdown__item',
		TITLE: 'ac-account-switch-dropdown__title',
		CONTENT: 'ac-account-switch-dropdown__content',
	},
	NAVIGATION: {
		LIST: 'ac-account-switch__list',
		SUB_LIST: 'ac-account-switch__list--sub',
		ITEM: 'ac-account-switch__item',
		LINK: 'ac-account-switch__link',
		ACTIVE: 'ac-account-switch__link--active',
		ICON: 'ac-account-switch__icon',
	},
};

const AcAccountSwitch = ({ store: { auth, profile, ui } }) => {
	const {
		current_profile,
		current_active_company,
		current_active_company_id,
		has_multiple_companies,
	} = profile;
	const { current_account_switch } = ui;
	const navigate = useNavigate();

	const [selected, setSelected] = useState(current_active_company_id);

	if (!has_multiple_companies) return null;

	const { t } = useTranslation();

	const $ref = useRef(null);

	const { handleToggle, handleClose, addEvents, removeEvents } = useUIActions(
		ui,
		KEYS.ACCOUNT_SWITCH,
		$ref
	);

	useEffect(() => {
		addEvents();

		return () => removeEvents();
	}, []);

	useEffect(() => {
		if (current_active_company_id !== selected)
			setSelected(current_active_company_id);
	}, [current_active_company_id]);

	const handleAccountSwitch = async (event, account) => {
		if (event?.preventDefault) event?.preventDefault();
		if (event?.stopPropagation) event?.stopPropagation();
		if (event?.persist) event?.persist();

		if (account?.id === current_active_company?.id) return;

		await profile.update({ active_company_id: account?.id }).then(() => {
			profile.setActiveCompany(account.id);
			if (navigate) navigate(DEFAULT_ROUTE.path, { replace: true });
		});
	};

	const getActiveCompanyName = useMemo(() => {
		if (!AcIsSet(current_profile?.company?.name)) return null;

		let {
			company: { name },
		} = current_profile;

		return name.replace(/,\s*$/, '');
	}, [current_profile]);

	const getInitials = useMemo(() => {
		if (!getActiveCompanyName) return null;

		return AcFormatInitials(getActiveCompanyName);
	}, [getActiveCompanyName]);

	const getIconClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.ICON);
	});

	const getActiveLinkClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LINK, _CLASSES.NAVIGATION.ACTIVE);
	});

	const getLinkClassNames = useCallback(
		(active = false) => {
			return clsx(
				_CLASSES.NAVIGATION.LINK,
				active && _CLASSES.NAVIGATION.ACTIVE
			);
		},
		[current_active_company]
	);

	const getItemClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.ITEM);
	});

	const getSubListClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LIST, _CLASSES.NAVIGATION.SUB_LIST);
	});

	const getListClassNames = useMemo(() => {
		return clsx(_CLASSES.NAVIGATION.LIST);
	});

	const getDropdownContentClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.CONTENT);
	});

	const getDropdownTitleClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.TITLE);
	});

	const getDropdownClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.MAIN);
	});

	const getDropdownWrpClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.WRP);
	});

	const getPointerClassNames = useMemo(() => {
		return clsx(_CLASSES.DETAILS.POINTER);
	});

	const getSublineClassNames = useMemo(() => {
		return clsx(_CLASSES.DETAILS.SUBLINE);
	});

	const getNameClassNames = useMemo(() => {
		return clsx(_CLASSES.DETAILS.NAME);
	});

	const getDetailsClassNames = useMemo(() => {
		return clsx(_CLASSES.DETAILS.MAIN);
	});

	const getInitialsClassNames = useMemo(() => {
		return clsx(_CLASSES.INITIALS);
	});

	const getToggleIconClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.ICON);
	});

	const getToggleClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.MAIN);
	});

	const getMainClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN,
			current_account_switch?.visible && _CLASSES.OPEN
		);
	}, [current_account_switch?.visible]);

	const isActive = (match, location) => {
		return match !== null ? match.url === location.pathname : false;
	};

	const renderListItem = ({ id, name }, selected) => {
		let company_name = name;
		company_name = company_name.replace(/,\s*$/, '');

		const active = selected === id;

		const object = (
			<li
				key={`ac-account-switch-item-${id}`}
				className={getItemClassNames}
				role={'presentation'}
				itemProp={'name'}
			>
				<button
					className={getLinkClassNames(active)}
					title={`Click to set "${company_name}" as your active company`}
					role={'menuitem'}
					onClick={(event) => handleAccountSwitch(event, { id, name })}
				>
					<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
					<AcIcon icon={ICONS.CHECK} />
					<span
						dangerouslySetInnerHTML={{
							__html: company_name,
						}}
					/>
				</button>
			</li>
		);

		return object;
	};

	const renderList = useMemo(() => {
		let collection = current_profile?.companies || [];

		// collection = collection.filter((n) => n.id !== current_active_company_id);

		const len = collection.length;
		let n = 0;
		let result = [];

		for (n; n < len; n++) {
			const item = collection[n];

			const object = renderListItem(item, selected);

			if (object) result.push(object);
		}

		return result;
	}, [
		current_profile?.accounts,
		current_active_company,
		current_active_company_id,
		selected,
	]);

	return (
		<div className={getMainClassNames} ref={$ref}>
			<div className={'ac-account-switch__backdrop'} onClick={handleClose} />

			<div
				className={getToggleClassNames}
				title={'Change your active company'}
				onClick={handleToggle}
			>
				<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
				<div className={getInitialsClassNames}>
					<AcIcon icon={ICONS.APARTMENT} />
				</div>
				<div className={getDetailsClassNames}>
					{getActiveCompanyName && (
						<Fade duration={300} spy={getActiveCompanyName}>
							<div
								className={getNameClassNames}
								dangerouslySetInnerHTML={{
									__html: getActiveCompanyName,
								}}
							/>
						</Fade>
					)}
					<Fade duration={300} appear>
						<div
							className={getSublineClassNames}
							dangerouslySetInnerHTML={{
								__html: 'Active company',
							}}
						/>
					</Fade>
				</div>
				<AcIcon icon={ICONS.CHEVRON_DOWN} className={getPointerClassNames} />
			</div>

			<div className={getDropdownWrpClassNames}>
				<div className={getDropdownClassNames}>
					<AcHeading rank={6} className={getDropdownTitleClassNames}>
						{TITLES.ACTIVE_COMPANY}
					</AcHeading>
					<AcRichContent
						className={getDropdownContentClassNames}
						content={`<p>You have access to multiple companies. Change your <strong>active company</strong> here to view it's related data.</p>`}
					/>

					<ul
						className={getListClassNames}
						role={'menubar'}
						itemScope
						itemType={'http://www.schema.org/SiteNavigationElement'}
					>
						{renderList}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default withStore(observer(AcAccountSwitch));
