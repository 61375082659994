// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import {
  DATETIME_FORMATS,
  KEYS,
  PERMISSIONS,
  THEMES,
  TITLES,
  TYPES,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcFormatDate } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
const AcDateRangeSelectionModal = loadable(() =>
  import(
    '@molecules/ac-date-range-selection-modal/ac-date-range-selection-modal.web'
  )
);

// Imports => Components
const AcTable = loadable(() => import('@components/ac-table/ac-table.web'));

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcCard = loadable(() => import('@atoms/ac-card/ac-card.web'));
const AcDivider = loadable(() => import('@atoms/ac-divider/ac-divider.web'));
const AcEmptyBlock = loadable(() =>
  import('@atoms/ac-empty-block/ac-empty-block.web')
);
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcRichContent = loadable(() =>
  import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcDatepickerInput = loadable(() =>
  import('@atoms/ac-datepicker-input/ac-datepicker-input.web')
);
const AcSelectBox = loadable(() =>
  import('@atoms/ac-select-box/ac-select-box.web')
);
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
  MAIN: 'ac-control-unit-detail-settings-tab',
};

const AcControlUnitDetailSettingsTab = ({
  data,
  store: { control_units, reports, ui },
}) => {
  const { can, cannot } = usePermissions();

  const { current_control_unit, current_control_unit_reports, is_busy } =
    control_units;

  let dateStart = new Date();
  dateStart = dateStart.setFullYear(dateStart.getFullYear() - 1);

  const [fields, setFields] = useState({
    date_start: dateStart,
    date_end: new Date(),
  });

  const { handleInputChange } = useFormActions({
    fields,
    setFields,
  });

  useEffect(() => {
    if (AcIsSet(fields.date_start) && AcIsSet(fields.date_end)) {
      handleGetReports();
    }
  }, [fields]);

  const handleDateRangeSelected = async ({ start, end }) => {
    setFields({
      date_start: start,
      date_end: end,
    });

    ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
  };

  const displayDateRangeSelectionModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.DATE_RANGE_SELECTION,
      body: (
        <AcDateRangeSelectionModal
          value={{ start: fields.date_start, end: fields.date_end }}
          submit={handleDateRangeSelected}
          cancel={() => {
            ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
          }}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: async () => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const handleGetReports = () => {
    if (!AcIsSet(data)) return;
    if (!AcIsSet(fields)) return;

    const date_start = AcFormatDate(
      fields.date_start,
      null,
      DATETIME_FORMATS.RAW_DATE
    );
    const date_end = AcFormatDate(
      fields.date_end,
      null,
      DATETIME_FORMATS.RAW_DATE
    );

    const params = {
      date_start,
      date_end,
    };

    control_units.list_reports(data.id, params);
  };

  const getStartDateInputOptions = useMemo(() => {
    const end = new Date(fields.date_end);
    const start = new Date(fields.date_start);

    return {
      type: TYPES.DATE,
      label: 'Start date',
      placeholder: 'dd/mm/yyyy',
      name: 'date_start',
      value: start,
      range: 'start',
      readonly: true,
      onclick: displayDateRangeSelectionModal,
    };
  }, [fields, fields.date_start]);

  const getEndDateInputOptions = useMemo(() => {
    const end = new Date(fields.date_end);
    const start = new Date(fields.date_start);

    return {
      type: TYPES.DATE,
      label: 'End date',
      placeholder: 'dd/mm/yyyy',
      name: 'date_end',
      value: end,
      range: 'end',
      readonly: true,
      onclick: displayDateRangeSelectionModal,
    };
  }, [fields, fields.date_end]);

  const getPdf = async (event, data) => {
    event.preventDefault();
    const {
      link: { id: hash },
    } = data;

    const pdf = await reports.pdfLink(hash);
    window.open(pdf.link);
  };
  const renderTable = useMemo(() => {
    if (is_busy) return <AcLoader loading={true} />;
    if (!AcIsSet(current_control_unit_reports))
      return <AcEmptyBlock message={'No reports found to display.'} />;

    const { data, headers, meta } = current_control_unit_reports;

    if (!data || data.length === 0)
      return <AcEmptyBlock message={'No reports found to display.'} />;

    const options = {
      columns: headers,
      rows: data,
      onDownload: cannot(PERMISSIONS.REPORT.DOWNLOAD) ? null : reports.download,
      pagination: null,
      renderColumnData: {
        'hash-pdf': (data) => {
          return cannot(PERMISSIONS.REPORT.DOWNLOAD) ? null : (
            <a href="#" onClick={(event) => getPdf(event, data)}>
              PDF
            </a>
          );
        },
      },
    };

    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [current_control_unit_reports, is_busy]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn>
            <AcHeading rank={5}>Piling reports</AcHeading>
            <AcRichContent content={'<p>Piling reports</p>'} />
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            <AcCard dense flat className={'h-padding-top-10'}>
              <AcContainer fluid>
                <AcRow className={'h-padding-x-15'}>
                  <AcColumn xs={12} sm={6} md={3} lg={3}>
                    <AcDatepickerInput {...getStartDateInputOptions} />
                  </AcColumn>

                  <AcColumn xs={12} sm={6} md={3} lg={3}>
                    <AcDatepickerInput {...getEndDateInputOptions} />
                  </AcColumn>
                </AcRow>
                <AcRow>
                  <AcColumn>
                    <AcDivider />
                  </AcColumn>
                </AcRow>
                <AcRow className={'h-margin-bottom-25'}>
                  <AcColumn>{renderTable}</AcColumn>
                </AcRow>
              </AcContainer>
            </AcCard>
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcControlUnitDetailSettingsTab));
