// Imports => React
import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import loadable from '@loadable/component';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';
import { AcUUID } from '@utils';

// Imports => Constants
import {
  COLUMNS,
  DATETIME_FORMATS,
  ICONS,
  KEYS,
  PERMISSIONS,
  THEMES,
  ROUTES,
  TITLES,
  TYPES,
  VARIANTS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcFormatDate, AcFormatInternalURI } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';
import AcEditContractModal from '@molecules/ac-edit-contract-modal/ac-edit-contract-modal.web';
import AcSelectEquipment from '@molecules/ac-import-company/components/ac-select-equipment';

// Imports => Components
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';
const AcTable = loadable(() => import('@components/ac-table/ac-table.web'));
const AcWizardModal = loadable(() =>
  import('@components/ac-wizard-modal/ac-wizard-modal')
);

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcEmptyBlock from '@atoms/ac-empty-block/ac-empty-block.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';
import {
  ConnectedExpDateModal,
  ConnectedExpRemoveDateModal,
} from '@components/ac-exp-date-modal';
import AcOrginalOrderList from './original-order-list';

const _CLASSES = {
  MAIN: 'ac-contract-detail-overview-tab',
};

const AcContractDetailOverviewTab = ({
  data,
  store: { contracts, erp, ui, profile },
}) => {
  const { can, cannot } = usePermissions();
  const navigate = useNavigate();

  const displayEditModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: `${TITLES.EDIT_CONTRACT}: ${data.name}`,
      body: <AcEditContractModal data={data} submit={contracts.update} />,
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const showOrderList = async () => {
    if (!AcIsSet(data)) return null;

    await ui.reset(KEYS.NEW_WINDOW);

    const winWidth = parseInt(window.innerWidth / 1.5, 10);
    const winHeight = parseInt(window.innerHeight / 1.5, 10);
    let width = winWidth;
    let height = winHeight;

    if (winWidth < 1101) width = 1101;
    if (winHeight < 768) height = 768;

    const options = {
      id: AcUUID(),
      title: ` ${data.name}- ${TITLES.ORDER_LIST} - ${TITLES.BASE}`,
      name: 'cu-orderlist',
      center: 'screen',
      onExit: () => {
        ui.reset(KEYS.NEW_WINDOW);
        if (AcIsSet(window._lvTimer)) clearTimeout(window._lvTimer);
        if (AcIsSet(window._lvProgress)) clearTimeout(window._lvProgress);
      },
      features: {
        toolbar: 'no',
        location: 'no',
        status: 'no',
        menubar: 'no',
        popup: 'yes',
        scrollbars: 'yes',
        resizable: 'yes',
        width: width,
        height: height,
      },
    };

    const body = <AcOrginalOrderList contract={data} />;

    ui.set(KEYS.NEW_WINDOW, { options, body });
  };

  const submitSelectedEquipment = async () => {
    await erp.store();
    if (navigate) navigate(ROUTES.CONTRACT_DETAIL.path.replace(':id', data.id));
  };

  const steps = useMemo(() => {
    return [
      {
        title: `Select equipment from ${data.company?.name}`,
        children: <AcSelectEquipment />,
      },
    ];
  }, [data.company]);

  const AcWizardModalBody = useMemo(() => {
    return (
      <AcWizardModal
        onSubmit={submitSelectedEquipment}
        onCancel={erp.resetAll}
        steps={steps}
        progress
      />
    );
  }, [steps]);

  useEffect(() => ui.setModalBody(AcWizardModalBody), [AcWizardModalBody]);

  const handleAddEquipment = async () => {
    if (!AcIsSet(data)) return;

    await erp.set(KEYS.COMPANY, data.company);
    await erp.getContract(data.id);

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      body: AcWizardModalBody,
      closeable: false,
      visible: true,
      actions: [],
      forceRerender: true,
      width: 600,
    });
  };

  const displayRemoveExpirationDateModal = async (object) => {
    const { id } = object;
    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EXPIRATION_DATE_REMOVE,
      body: (
        <ConnectedExpRemoveDateModal
          equipmentNo={parseInt(id, 10)}
          contractId={data.id}
          submit={contracts.updateEquipmentExpDate}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: async () => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const displayExpirationDateModal = async (object) => {
    const { id } = object;
    const expires_at = object['pivot.expires_at'] || '';
    const expiresDate = AcFormatDate(
      expires_at || new Date(),
      DATETIME_FORMATS.DATE,
      DATETIME_FORMATS.RAW_DATE
    );
    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EXPIRATION_DATE_SET,
      body: (
        <ConnectedExpDateModal
          equipmentNo={parseInt(id, 10)}
          contractId={data.id}
          expires={new Date(expiresDate)}
          submit={contracts.updateEquipmentExpDate}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: async () => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const handleRemoveEquipment = (object) => {
    const { object_no: name, id } = object;
    ui.confirm({
      instance: contracts,
      title: TITLES.REMOVE_EQUIPMENT,
      content: `<p>You are about to remove equipment <strong>${name}</strong> from this contract.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
      confirm: {
        label: 'Yes, remove',
        callback: () => contracts.remove_equipment(data.id, object),
      },
    });
  };
  const getShowOrderListButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Open order list',
      callback: showOrderList,
    };
  }, [data]);

  const renderDetails = useMemo(() => {
    if (!data) return null;

    const { name, company, date_start, date_end } = data;

    const first_day = AcFormatDate(
      date_start,
      null,
      DATETIME_FORMATS.LOCAL_DATE
    );
    const last_day = AcFormatDate(date_end, null, DATETIME_FORMATS.LOCAL_DATE);
    const first_last_day = `${first_day || ''} - ${last_day || ''}`;

    const company_route =
      (can(PERMISSIONS.COMPANY.READ) &&
        company &&
        AcFormatInternalURI(
          { id: company.id, entity: KEYS.COMPANY },
          'View company'
        )) ||
      null;

    const items = [
      {
        label: 'Name',
        value: name || '-',
      },
      {
        label: 'Assigned to company',
        value: (company && company.name) || '-',
        type: TYPES.LINK,
        to: company_route,
      },
      {
        label: 'First day - Last day',
        value: first_last_day,
      },
    ];

    const edit = can(PERMISSIONS.CONTRACT.MANAGE) && displayEditModal;

    return (
      <AcDetailsCard
        actions={
          <AcButton {...getShowOrderListButtonOptions}>
            <AcIcon icon={ICONS.OPEN_IN_NEW} />
            <span>Open original order list</span>
          </AcButton>
        }
        title={TITLES.DETAILS}
        items={items}
        edit={edit}
      />
    );
  }, [data]);

  const renderEquipmentTable = useMemo(() => {
    if (contracts.is_loading) return <AcLoader loading={true} />;
    if (!AcIsSet(data)) return null;
    if (
      !AcIsSet(data.equipment) ||
      !AcIsSet(data.equipment.data) ||
      data.equipment.data.length === 0
    )
      return (
        <AcEmptyBlock
          message={'No equipment assigned to this rental contract yet.'}
        />
      );

    const { equipment } = data;

    const options = {
      columns: equipment.headers,
      rows: equipment.data,
      pagination: null,
      withIcon: true,
      actions: [
        [
          {
            show: (data) => {
              return (
                !!data.find(
                  (d) =>
                    d.key === 'equipmentType.equipmentGroup.name_plural' &&
                    d.value.indexOf('Control') > -1
                ) &&
                !!data.find((d) => d.key === 'pivot.expires_at' && !d.value)
              );
            },
            label: 'Set expiration date',
            field: KEYS.OBJECT,
            icon: ICONS.CALENDAR_CLOCK,
            type: TYPES.DELETE,
            callback: displayExpirationDateModal,
            disabled: cannot(PERMISSIONS.CONTRACT.MANAGE),
          },
        ],
        [
          {
            show: (data) => {
              return !!data.find(
                (d) => d.key === 'pivot.expires_at' && d.value
              );
            },
            label: 'Remove expiration date',
            field: KEYS.OBJECT,
            icon: ICONS.CALENDAR_AVAILABLE,
            type: TYPES.DELETE,
            callback: displayRemoveExpirationDateModal,
            disabled: cannot(PERMISSIONS.CONTRACT.MANAGE),
          },
        ],
        [
          {
            label: 'Remove',
            field: KEYS.OBJECT,
            icon: ICONS.TRASH_CAN_OUTLINE,
            type: TYPES.DELETE,
            callback: handleRemoveEquipment,
            disabled: cannot(PERMISSIONS.CONTRACT.MANAGE),
          },
        ],
      ],
    };

    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [data, contracts.is_loading]);

  const renderUsersTable = useMemo(() => {
    if (contracts.is_loading) return <AcLoader loading={true} />;
    if (!AcIsSet(data)) return null;
    if (!AcIsSet(data.company)) return null;
    if (!AcIsSet(data.company.users) || data.company.users.length === 0)
      return <AcEmptyBlock message={'No users linked to this company yet.'} />;

    const {
      company: { users },
    } = data;

    const options = {
      columns: COLUMNS.USERS,
      rows: users,
      pagination: null,
    };

    return (
      <Fade duration={300}>
        <AcTable {...options} />
      </Fade>
    );
  }, [data, contracts.is_loading]);

  const getAddEquipmentButtonOptions = useMemo(() => {
    return {
      rel: 'ac-add-button',
      type: TYPES.BUTTON,
      theme: THEMES.ALPHA,
      title: 'Add Equipment',
      callback: handleAddEquipment,
      disabled:
        cannot(PERMISSIONS.CONTRACT.MANAGE) ||
        !AcIsSet(data) ||
        contracts.is_busy,
      loading: contracts.is_busy,
    };
  }, [data, contracts.is_busy]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn>
            <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
              Contract details
            </AcHeading>
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-45'}>
          <AcColumn xs={12} sm={6}>
            {renderDetails}
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn xs={12} sm={6}>
            <AcHeading tag={'h2'} rank={5}>
              Equipment
            </AcHeading>
            <AcRichContent
              content={'<p>The equipment assigned to this rental contract.</p>'}
            />
          </AcColumn>

          {profile?.current_profile?.is_iqip_user && (
            <AcColumn xs={12} sm={6} className={'h-text--align-right'}>
              <AcButton {...getAddEquipmentButtonOptions}>
                <AcIcon icon={ICONS.PLUS} />
                <span>Add equipment</span>
              </AcButton>
            </AcColumn>
          )}
        </AcRow>

        <AcRow className={'h-margin-bottom-45'}>
          <AcColumn xs={12}>
            <AcCard dense flat>
              {renderEquipmentTable}
            </AcCard>
          </AcColumn>
        </AcRow>

        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn xs={12}>
            <AcHeading tag={'h2'} rank={5}>
              Users
            </AcHeading>
            <AcRichContent
              content={'<p>The users linked to this company.</p>'}
            />
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn xs={12}>
            <AcCard dense flat>
              {renderUsersTable}
            </AcCard>
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcContractDetailOverviewTab));
