// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import {
  ICONS,
  KEYS,
  PERMISSIONS,
  ROLES,
  ROUTES,
  SIZES,
  THEMES,
  TITLES,
  TYPES,
  VARIANTS,
} from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcSortBy,
  AcFormatRole,
  AcGenerateBasicPassword,
  AcGenerateAdvancedPassword,
} from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcCheckbox from '@atoms/ac-checkbox/ac-checkbox.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-add-user-modal',
  CONTENT: 'ac-add-user-modal__content',
};

const AcEditUserModal = ({
  store: { ui, users, profile },
  multistep = {
    roles: false,
    companies: false,
  },
  data,
  submit,
  cancel,
  callback,
}) => {
  let raw_fields = {
    name: data?.name || '',
    email: data?.email || '',
    phone: data?.phone || '',
  };
  let raw_errors = {
    name: data?.name ? null : undefined,
    email: data?.email ? null : undefined,
    phone: data?.phone ? null : undefined,
  };

  const navigate = useNavigate();
  const { can, cannot } = usePermissions();

  const [fields, setFields] = useState(raw_fields);
  const [errors, setErrors] = useState(raw_errors);

  const [newUserObject, setNewUserObject] = useState({
    name: data?.name,
    email: data?.email,
    phone: data?.phone,
    id: data?.id,
  });

  const { hasErrors, handleInputChange, handleInputValidation } =
    useFormActions({
      fields,
      setFields,
      errors,
      setErrors,
    });

  const resetNewUserObject = () => {
    setNewUserObject(data);
  };

  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
    if (cancel) cancel();
  };

  const handleSubmit = (event) => {
    if (event && event.preventDefault) event.preventDefault();

    handleAssignValuesToNewUser();
  };

  const handleAssignValuesToNewUser = useCallback(async () => {
    return new Promise(async (resolve) => {
      let obj = newUserObject;

      obj.name = fields.name;
      obj.email = fields.email;
      obj.phone = fields.phone;

      await setNewUserObject(obj);

      await users.update(newUserObject).then(async (response) => {
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
        navigate(ROUTES.USER_DETAIL.path.replace(':id', response.id));
      });

      resolve();
    });
  }, [fields]);

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getNameInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Name',
      name: 'name',
      value: fields.name,
      callback: handleInputChange,
      validation: handleInputValidation,
      focus: true,
    };
  }, [fields, fields.name, data?.name]);

  const getEmailInputOptions = useMemo(() => {
    return {
      type: TYPES.EMAIL,
      label: 'Email address',
      placeholder: 'name@domain.com',
      name: 'email',
      value: fields.email,
      callback: handleInputChange,
      validation: handleInputValidation,
      disabled: data?.is_iqip_user || !profile?.current_profile?.is_iqip_user,
    };
  }, [
    fields,
    fields.email,
    data?.email,
    data?.is_iqip_user,
    profile?.current_profile,
  ]);

  const getPhoneInputOptions = useMemo(() => {
    return {
      type: TYPES.PHONE,
      label: 'Phone number',
      placeholder: 'Phone number',
      name: 'phone',
      required: false,
      value: fields.phone,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.phone, data?.phone]);

  const getCancelButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Cancel',
      callback: handleCancel,
    };
  });

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      disabled: errors && hasErrors,
      title: 'Save',
      callback: handleSubmit,
    };
  }, [fields, errors, hasErrors]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <form method={'post'} onSubmit={handleSubmit}>
          <AcContainer fluid>
            <AcRow>
              <AcColumn>
                <AcTextInput {...getNameInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcTextInput {...getEmailInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcTextInput {...getPhoneInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow className={'h-margin-top-20'}>
              <AcColumn
                xxs={12}
                xs={7}
                sm={6}
                className={'h-text--align-left h-flex-v-align-center'}
              >
                <AcButton {...getCancelButtonOptions}>
                  <span>Cancel</span>
                </AcButton>
              </AcColumn>

              <AcColumn
                xxs={12}
                xs={5}
                sm={6}
                className={'h-text--align-right'}
              >
                <AcButton {...getSubmitButtonOptions}>
                  <span>{multistep ? 'Continue' : 'Save'}</span>
                </AcButton>
              </AcColumn>
            </AcRow>
          </AcContainer>
        </form>
      </div>
      {users.is_busy && <AcLoader loading={true} cover />}
    </div>
  );
};

export default withStore(observer(AcEditUserModal));
