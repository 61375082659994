// Imports => React
import React from 'react';
import clsx from 'clsx';
import Rollbar from 'rollbar/dist/rollbar.umd.min.js';

// Imports => Constants
import { ENVIRONMENTS } from '@constants';

// Imports => Config
import config from '@config';

// Imports => Utilities
import { AcIsSet, AcIsEmptyString } from '@utils';

class AcErrorBoundaryController extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = { hasError: false };

		if (this.rollbarIsEnabled()) this.rollbar = new Rollbar(config.rollbar);
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		this.throwError(error, errorInfo);
	}

	rollbarIsEnabled = () => {
		const {
			accessToken,
			payload: { environment },
		} = config.rollbar;

		const excluded = [
			ENVIRONMENTS.LOCAL,
			ENVIRONMENTS.TEST,
			ENVIRONMENTS.DEV,
			ENVIRONMENTS.DEVELOPMENT,
		];

		return (
			AcIsSet(accessToken) &&
			!AcIsEmptyString(accessToken) &&
			excluded.indexOf(environment) === -1
		);
	};

	logInfo = (info) => {
		// Do not log info messages during development or on the TEST environment
		if (!this.rollbarIsEnabled() || !AcIsSet(this.rollbar)) return;

		// Example log event using the rollbar object.
		this.rollbar.info(info || 'example info message from ac-error-boundary');
	};

	throwError = (error) => {
		// Do not log error messages during development or on the TEST environment
		if (!this.rollbarIsEnabled() || !AcIsSet(this.rollbar)) return;

		// Example error, which will be reported to rollbar.
		this.rollbar.error(error || 'example error from ac-error-boundary');
	};
}

export default AcErrorBoundaryController;
